<template>
  <div :class="$style.container">
    <div>
      <div :class="$style.titleCook">Готовятся</div>
      <orders-list :orders="cookingOrders" />
    </div>
    <div>
      <div :class="$style.titlePack">Готовы</div>
      <orders-list :orders="doneOrders" />
    </div>
  </div>
</template>

<script>
import OrderPushHandlerMixin from "@/mixins/OrderPushHandlerMixin";
import OrdersList from "./OrdersList";
export default {
  mixins: [OrderPushHandlerMixin],
  components: {
    OrdersList,
  },
  data() {
    return {
      interval: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch("setOrders", { filter: this.screenOrders });
      this.interval = setInterval(() => {
        this.$store.dispatch("setOrders", { filter: this.screenOrders });
      }, 15000);
    },
  },
  computed: {
    screenOrders() {
      return {
        department: this.$store.getters.currentDepartment.slug,
        stage: "status_screen",
        status: "open"
      };
    },
    cookingOrders() {
      return this.$store.getters.orders
        .filter((order) => order.status.is_packed === false)
        .reverse();
    },
    doneOrders() {
      return this.$store.getters.orders
        .filter(
          (order) =>
            order.status.is_packed === true && order.status.status === "open"
        )
        .reverse();
    },
  },
  watch: {
    screenOrders() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .titleCook {
    background-color: #ff6951;
    padding: 1rem 0;
    text-align: center;
    font-size: 5.5vh;
    line-height: 1;
  }
  .titlePack {
    background-color: #b6cc4e;
    padding: 1rem 0;
    text-align: center;
    font-size: 5.5vh;
    line-height: 1;
  }
}
</style>
