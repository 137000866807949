<template>
  <div class="container">
    <header class="header">
      <div class="header__logo-location">
        <img src="@/assets/img/logo.svg" />
        <OrdersLocationSelect />
      </div>
      <div @click="logout">
        <img class="logoutButton" src="@/assets/img/icons/logout.svg" alt="" />
      </div>
    </header>
    <div class="container-login-form">
      <img src="@/assets/img/logo-full.svg" />
      <h3 class="header">Выберите свою должность:</h3>
      <div v-for="role in roles" :key="role">
        <router-link
          :to="{ name: role }"
          v-if="roleTitleMap[role]"
          class="btn btn--secondary"
          >{{ roleTitleMap[role] }}</router-link
        >
        <a
          :href="href + '/takeaway_info_screen'"
          target="_blank"
          v-if="role === 'takeaway_info_screen'"
          class="btn btn--secondary"
          :class="$style.link"
          >Отображение заказов в зале <span>new</span>
        </a>
        <a
          :href="href + '/packing'"
          target="_blank"
          v-if="role === 'packer'"
          class="btn btn--secondary"
          :class="$style.link"
          >Упаковщик <span>new</span>
        </a>
        <a
          :href="href + '/sklad/receipt-invoices'"
          target="_blank"
          v-if="role === 'manager'"
          class="btn btn--secondary"
          :class="$style.link"
          >Управляющий(+склад) <span>new</span>
        </a>
        <a
          :href="href + '/cooking'"
          target="_blank"
          v-if="role === 'cook'"
          class="btn btn--secondary"
          :class="$style.link"
          >Повар <span>new</span>
        </a>
        <a
          :href="href + '/operator'"
          target="_blank"
          v-if="role === 'operator'"
          class="btn btn--secondary"
          :class="$style.link"
          >Оператор <span>new</span>
        </a>
        <a
          :href="href + '/director'"
          target="_blank"
          v-if="role === 'director'"
          class="btn btn--secondary"
          :class="$style.link"
          >Директор <span>new</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersLocationSelect from "@/components/common/OrdersLocationSelect";
import { CLEAR_USER } from "@/store/modules/auth/mutation-types";
import { clearAuthHeaders } from "@/utils/auth";

export default {
  name: "SelectRolePage",
  components: { OrdersLocationSelect },
  data: function () {
    return {
      roleTitleMap: {
        operator: "Оператор",
        cook: "Повар",
        packer: "Упаковщик",
        takeaway_info_screen: "Отображение заказов в зале",
        manager: "Управляющий",
        director: "Директор",
      },
      env: process.env.NODE_ENV,
    };
  },
  mounted() {
    localStorage.removeItem("filter");
    window.console.log(process.env.NODE_ENV);
  },
  methods: {
    logout() {
      localStorage.removeItem("filter");
      this.$store.commit(CLEAR_USER);
      clearAuthHeaders();
      this.$router.push({ name: "login" });
    },
  },
  computed: {
    roles() {
      return this.$store.getters.user.groups;
    },
    href() {
      return this.env === "development"
        ? "https://new-dash.korway.ru"
        : "https://dash.beex.is";
    },
  },
};
</script>

<style src="./auth-common.css"></style>

<style scoped>
.logoutButton {
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0 15px 0;
}

.btn--secondary {
  padding: 13px 25px;
  border-radius: 5px;
  display: block;
  margin: 15px 0;
}
</style>

<style lang="scss" module>
.link {
  position: relative;
  span {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    font-size: 0.75rem;
    color: #fff;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    background-color: #9ccc4d;
  }
}
</style>
