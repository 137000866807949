<template>
  <div
    class="order-item"
    v-if="isVisible"
    :class="{
      'order-item_done': isDone,
      'order-item_canceled': isCanceled,
      readonly: isCanceled,
    }"
  >
    <header class="order-item__header">
      <span class="order-item__header-number">{{ order.number }}</span>
      <span class="order-item__header-time">
        <span
          class="order-item__header-time-created"
          :class="{
            'order-item__header-time-created--muted': order.deliver_at,
          }"
          >{{ order.placed_at | time }}</span
        >
        <span v-if="order.deliver_at" class="order-item__header-time-deliver">
          <img src="@/assets/img/icons/alarm.svg" alt="" />
          <b>{{ order.deliver_at | time }}</b>
        </span>
      </span>
      <OrderListItemAlerts v-if="isBlacklisted" :order="order" />
    </header>
    <div class="order-item__body">
      <div class="order-item__address">
        <div v-if="order.contacts.phone">
          <span class="key">Тел: </span
          ><span class="val">{{ formattedPhone }}</span>
        </div>
        <div v-if="order.delivery_type === 'delivery'">
          <div v-if="order.address.street">
            <span class="key">Ул: </span
            ><span class="val">{{ order.address.street }}</span>
          </div>
          <div v-if="order.address.house">
            <span class="key">Дом: </span
            ><span class="val">{{ order.address.house }}</span>
          </div>
          <div v-if="order.address.apartment">
            <span class="key">Кв: </span
            ><span class="val">{{ order.address.apartment }}</span>
          </div>
          <div v-if="order.address.porch">
            <span class="key">Подъезд: </span
            ><span class="val">{{ order.address.porch }}</span>
          </div>
          <div v-if="order.address.floor">
            <span class="key">Этаж: </span
            ><span class="val">{{ order.address.floor }}</span>
          </div>
          <div v-if="order.address.door_code">
            <span class="key">Домофон: </span
            ><span class="val">{{ order.address.door_code }}</span>
          </div>
        </div>

        <div v-if="order.contacts.first_name">
          <span class="key">Имя: </span
          ><span class="val">{{ order.contacts.first_name }}</span>
        </div>
        <div v-if="order.contacts.table_number">
          <span class="key">Номер стола: </span
          ><span class="val">{{ order.contacts.table_number }}</span>
        </div>
        <div v-if="order.persons_qty">
          <span class="key">Кол-во персон: </span
          ><span class="val">{{ order.persons_qty }}</span>
        </div>
        <div v-if="order.comment">
          <span class="key">Коммент: </span
          ><span class="val">{{ order.comment }}</span>
        </div>
      </div>
      <div class="order-item__products">
        <div v-for="item in order.items" :key="order.id + item.id">
          {{ item.title }}
          <span class="order-item__products-product-count"
            >x {{ item.qty }}</span
          >
          -
          {{
            order.source === "aggregator"
              ? item.aggregator_price_per_item
              : item.price_per_item
          }}
          <div
            v-if="item.modifier_groups.length > 0"
            :style="{
              opacity: '0.5',
              fontSize: '13px',
            }"
          >
            <div v-for="i in item.modifier_groups" :key="i.id">
              {{ i.title }}:
              <span v-for="(j, index) in i.modifiers" :key="j.id">
                {{ j.title + " " + j.qty + "X"
                }}{{ index !== i.modifiers.length - 1 ? "," : "." }}
              </span>
            </div>
          </div>
          <div
            :style="{
              fontSize: '14px',
            }"
            v-if="item.comment"
          >
            Комментарий:
            {{ item.comment }}
          </div>
        </div>
      </div>
      <div class="order-item__payment-info">
        <div class="order-item__payment-info-row">
          <svg-icon
            name="cash"
            class-name="order-item__payment-info-icon"
          ></svg-icon>
          {{ order.payment_info.subtotal | money }}
        </div>
        <div class="order-item__payment-info-row">
          <svg-icon
            name="points-coins"
            class-name="order-item__payment-info-icon"
          ></svg-icon>
          {{ order.payment_info.points_used }}
        </div>
        <div
          v-if="order.payment_info.discount_percent_amount !== '0.00'"
          class="order-item__payment-info-row"
        >
          <svg-icon
            name="coupon"
            class-name="order-item__payment-info-icon"
          ></svg-icon>
          {{ getRest }}
        </div>
        <div
          v-if="order.payment_info.service_percent_amount !== '0.00'"
          class="order-item__payment-info-row"
        >
          <svg-icon
            name="services"
            class-name="order-item__payment-info-icon"
          ></svg-icon>
          {{ getService }}
        </div>
        <div
          v-if="order.payment_info.promo_code_amount !== '0.00'"
          class="order-item__payment-info-row"
        >
          <svg-icon
            name="coupon"
            class-name="order-item__payment-info-icon"
          ></svg-icon>
          ({{ order.promo_code.code }})
          {{ order.payment_info.promo_code_amount }}
        </div>
        <div
          v-if="
            order.delivery_type === 'delivery' &&
            order.payment_info.delivery_price > 0
          "
          class="order-item__payment-info-row"
        >
          <svg-icon
            name="delivering"
            class-name="order-item__payment-info-icon"
          ></svg-icon>
          {{ order.payment_info.delivery_price }}
        </div>
        <div
          class="order-item__payment-info-row order-item__payment-info-row_total"
        >
          Итого:
          <b>{{ order.payment_info.total_price | money }}</b>
        </div>
        <div
          v-if="order.payment_info.rest_from > 0"
          class="order-item__payment-info-row"
        >
          Купюра:
          <span>{{ order.payment_info.rest_from }}</span>
        </div>
        <div
          v-if="order.payment_info.rest_from > 0"
          class="order-item__payment-info-row"
        >
          Сдача:
          <span>{{ restAmount | money }}</span>
        </div>
        <div
          v-if="
            order.payment_info.total_paid < order.payment_info.total_price &&
            order.payment_info.payment_type === 'online' &&
            order.payment_info.total_paid > 0
          "
          class="order-item__payment-info-row"
          :style="{ marginTop: '8px' }"
        >
          <span>Цена после оплаты изменилась</span>
        </div>
      </div>
      <div class="order-item__stages-actions">
        <div class="order-item__stages">
          <div class="order-item__stage">
            <img :src="paymentIcon" alt="" class="order-item__stage-icon" />
          </div>

          <div
            @click="openEditPage"
            v-if="hasModule(modules.orderEditing)"
            class="order-item__stage"
          >
            <div>
              <svg-icon
                v-if="
                  !order.department &&
                  order.status.is_confirmed &&
                  (order.source === 'call_center' ||
                    order.source === 'site' ||
                    order.source === 'app' ||
                    order.source === 'aggregator')
                "
                :name="'stage-confirmation'"
                class-name="order-item__stage-icon"
              ></svg-icon>
              <svg-icon
                v-else-if="!order.status.is_confirmed && order.is_autoconfirmed"
                name="stage-confirmation-no-colling"
                class-name="order-item__stage-icon"
              ></svg-icon>
              <svg-icon
                v-else-if="order.status.is_confirmed && order.is_autoconfirmed"
                name="stage-confirmation-no-colling-done"
                class-name="order-item__stage-icon"
              ></svg-icon>
              <svg-icon
                v-else
                :name="
                  order.status.is_confirmed
                    ? 'stage-confirmation--done'
                    : 'stage-confirmation'
                "
                class-name="order-item__stage-icon"
              ></svg-icon>
            </div>
          </div>
          <div v-else class="order-item__stage" @click="markConfirmed(true)">
            <svg-icon
              v-if="!order.status.is_confirmed && order.is_autoconfirmed"
              name="stage-confirmation-no-colling"
              class-name="order-item__stage-icon"
            ></svg-icon>
            <svg-icon
              v-else-if="order.status.is_confirmed && order.is_autoconfirmed"
              name="stage-confirmation-no-colling-done"
              class-name="order-item__stage-icon"
            ></svg-icon>
            <svg-icon
              v-else
              :name="
                order.status.is_confirmed
                  ? 'stage-confirmation--done'
                  : 'stage-confirmation'
              "
              class-name="order-item__stage-icon"
            ></svg-icon>
          </div>

          <div class="order-item__stage" v-if="hasModule(modules.cooking)">
            <svg-icon
              :name="
                order.status.is_cooked ? 'stage-cooking--done' : 'stage-cooking'
              "
              class-name="order-item__stage-icon"
            ></svg-icon>
          </div>
          <div class="order-item__stage" v-if="hasModule(modules.packing)">
            <svg-icon
              :name="
                order.status.is_packed ? 'stage-packing--done' : 'stage-packing'
              "
              class-name="order-item__stage-icon"
            ></svg-icon>
          </div>
          <div
            class="order-item__stage"
            :class="isLoading ? 'readonly' : ''"
            @click="onDeliveryIconClick"
          >
            <img
              :src="deliveryStageIconData.icon"
              alt=""
              class="order-item__stage-icon"
            />
          </div>
        </div>
        <!-- <div
          v-if="
            (order.is_confirming_in_dostavix ||
              order.is_confirming_in_callcenter) &&
            !order.status.is_confirmed
          "
        >
          Заказ уже подтверждается
        </div> -->
        <span v-if="deliveryStageIconData.caption">Курьер:</span>
        {{ deliveryStageIconData.caption }}
        <div class="order-item__cancellation-info" v-if="isCanceled">
          Причина: <b>{{ order.cancellation.refuse_reason.title }}</b
          ><br />
          Комментарий: <b>{{ order.cancellation.refuse_comment }}</b>
        </div>
        <div class="order-item__actions">
          <dropdown
            v-if="
              hasModule(modules.orderEditing) ||
              hasModule(modules.blacklist) ||
              hasModule(modules.privateComments)
            "
            class="order-item__actions-action order-item__action-more"
            align="left"
          >
            <template slot="btn" class="">
              <img
                src="@/assets/img/icons/three-dots.svg"
                alt=""
                class="order-item__action-more-label"
              />
            </template>
            <template slot="body">
              <div
                class="order-item__action-more-item"
                :class="{ readonly: readonlyType }"
                @click="triggerPaymentType"
                v-if="
                  order.payment_info.payment_type !==
                    Order.PAYMENT_TYPE.online && hasModule(modules.orderEditing)
                "
              >
                <span
                  v-if="
                    order.payment_info.payment_type === Order.PAYMENT_TYPE.cash
                  "
                  >Оплата POS терминалом</span
                >
                <span
                  v-else-if="
                    order.payment_info.payment_type === Order.PAYMENT_TYPE.pos
                  "
                  >Оплата наличными</span
                >
              </div>
              <div
                class="order-item__action-more-item"
                v-if="order.user && hasModule(modules.blacklist)"
                @click="triggerBlackList"
              >
                <span v-if="order.user.blacklist_comment"
                  >Удалить из черного списка</span
                >
                <span v-else>Добавить в черный список</span>
              </div>
              <div
                class="order-item__action-more-item"
                v-if="order.user && hasModule(modules.privateComments)"
                @click="editPrivateComment"
              >
                <span v-if="order.user.private_comment"
                  >Изменить комментарий о клиенте</span
                >
                <span v-else>Добавить комментарий о клиенте</span>
              </div>
              <div
                v-if="order.delivery_type !== 'stay'"
                class="order-item__action-more-item"
                @click="changeDel"
                :class="{ readonly: getReadonly === true || order.closed_at }"
              >
                Сменить тип доставки
              </div>
              <div
                v-if="order.delivery_type === 'delivery'"
                class="order-item__action-more-item"
                @click="removeCourier"
                :class="{ readonly: getReadonly === true || order.closed_at }"
              >
                Удалить курьера
              </div>
              <div
                v-if="$store.getters.user.groups.includes('manager')"
                class="order-item__action-more-item"
                @click="cancelConfirmation"
              >
                Отменить подтверждение
              </div>
            </template>
          </dropdown>

          <div class="order-item__action-print-receipt"></div>
          <div
            v-if="
              hasModule(modules.receipts) ||
              hasModule(modules.orderCancellation)
            "
            class="order-item__actions-action"
          >
            <button
              v-if="order.status.is_confirmed"
              class="btn btn--secondary btn--size-default order-item__actions-btn"
              :class="{
                'receipt-btn-loading': isNoFiscalReceiptLoading,
              }"
              @click="onReceiptPrint(true)"
            >
              Пречек
            </button>
            <button
              v-if="order.status.is_confirmed"
              class="btn btn--secondary btn--size-default order-item__actions-btn"
              :class="{
                'receipt-btn-disabled': order.is_receipt_printed,
                'receipt-btn-loading': isReceiptLoading,
              }"
              @click="onReceiptPrint(false)"
            >
              Чек
            </button>
            <router-link
              v-if="
                order.status.status === Order.STATUS.open &&
                hasModule(modules.orderCancellation)
              "
              :to="{
                name: 'order-cancel-page',
                params: { id: order.id },
              }"
            >
              <button
                class="btn btn--danger btn--size-default order-item__actions-btn"
              >
                Отказ
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import "../../assets/sass/variables"

.bp-dropdown
    &__body
        padding: 0
        border-radius: 5px

    &__btn
        border-radius: $border-radius-large
        background: $blue-light
        padding: 10px 25px
</style>

<style lang="sass" scoped>
@import "../../assets/sass/variables"
@import "../../assets/sass/mixins"
.receipt-btn-disabled
  pointer-events: none
  background: #B8BEC7
  color: white !important
.receipt-btn-loading
  pointer-events: none
  opacity: 0.3
h4
    margin: 0 1rem 0 0
.order-item
    @include section
    display: flex
    flex-direction: column

    &_done
        background: #F6F6F6
    &_canceled
        background: #FFE6E6

    &__header
        font-size: 25px
        padding: 20px 0
        display: flex
        align-items: baseline

        &-number
            font-weight: bold
        &-time
            margin-left: 20px
            display: flex
            align-items: baseline
            &-created
                font-weight: bold
                color: $contrast-color
                white-space: nowrap
                &--muted
                    color: $grey-light
            &-deliver
                display: flex
                justify-content: center
                align-items: center
                margin-left: 20px
                padding: 5px 25px
                background: #FFBFBF
                color: #fff
                font-weight: bold
                border-radius: $border-radius-large
                b
                  white-space: nowrap
                img
                    padding-right: 10px
        &-icon
            margin-left: 20px

    &__body
        display: flex
        flex-direction: row
        align-items: center
        padding: 20px 0

        & > div
            width: 30%
            padding: 0 1rem 0 0

    &__address
        .key
            font-weight: 300
            color: $secondary-text-color
        .val
            font-weight: bold
            word-wrap: break-word

    &__products
        font-weight: bold
        width: 24% !important

        &-product-count
            color: $contrast-color

    &__payment-info
        &-row
            max-width: 150px
            white-space: nowrap
            margin: 0 auto
            display: flex
            justify-content: space-between
            font-weight: bold

            &_total
                color: $contrast-color
                margin-top: 8px
                padding-top: 8px
                border-top: 1px solid black

        &-icon
            width: 1.2em
            height: 1.2em

    &__stages
        display: flex
        justify-content: flex-end
        padding: $vertical-padding-default 0

        &-actions
            min-width: 430px

    &__stage
        padding: 0 15px
        display: flex
        flex-direction: column

        &:first-child
            padding-left: 0
        &:last-child
            padding-right: 0

        &-icon
            width: 61px
            height: 61px
            cursor: pointer

    &__cancellation-info
        padding-top: $vertical-padding-default

    &__actions
        display: flex
        flex-direction: row
        justify-content: flex-end
        padding-top: $vertical-padding-default

        &-action
            &:first-child
                padding-left: 0

            &:last-child
                padding-right: 0

        &-btn
            font-size: 14px
            margin-left: 15px

    &__action-more
        &-item
            padding: 10px 20px
            cursor: pointer
            white-space: nowrap
            text-align: right

            &:hover
                background: #f0f0f0
        &-label
            height: 19px
            margin-right: 10px
</style>

<style lang="scss" module>
.getOrder {
  cursor: pointer;
  font-size: 1.2rem;
}
.icon {
  width: 61px !important;
  height: 61px;
}
.button {
  outline: 0;
  border: none;
  &:active,
  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}
</style>

<script>
import SvgIcon from "@/components/SvgIcon";
import Order from "@/api/order";
import Department from "@/api/department";
import { format } from "date-fns";
import Dropdown from "bp-vuejs-dropdown";
import printReceipt from "@/utils/receipt";
import OrderDateTimeToCountFrom from "@/mixins/OrderDateTimeToCountFrom";
import { mapGetters, mapMutations } from "vuex";
import OrderListItemAlerts from "@/components/order/OrderListItemAlerts";

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

export default {
  name: "OrdersListItem",
  mixins: [OrderDateTimeToCountFrom],
  components: { SvgIcon, Dropdown, OrderListItemAlerts },
  props: {
    order: {
      type: Object,
      required: true,
    },
    now: {
      type: Number,
      required: true,
    },
    depCount: {
      type: Boolean,
    },
  },
  data: () => {
    return {
      Order: Order,
      modules: Department.MODULES,
      readonlyType: false,
      isReceiptLoading: false,
      isNoFiscalReceiptLoading: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations(["changeIsModalOpen", "readonlyChange"]),
    changeReadonly() {
      this.readonlyType = false;
    },
    removeCourier() {
      this.$store.dispatch("removeCourier", { orderId: this.order.id });
      this.$root.$emit("bp-dropdown:hide");
    },
    async openEditPage() {
      if (
        !this.order.department &&
        this.order.status.is_confirmed &&
        this.order.source !== "dashboard"
      ) {
        let payload = {
          field: "department",
          value: this.$store.getters.user.department.slug,
          orderId: this.order.id,
          isCheck: true,
        };
        await this.$store.dispatch("setOrderField", payload);
      }
      // if (
      //   (this.order.is_confirming_in_dostavix ||
      //     this.order.is_confirming_in_callcenter) &&
      //   !this.order.status.is_confirmed
      // ) {
      //   return;
      // }
      this.$router.push({
        name: "order-edit-page",
        params: { id: this.order.id },
      });
    },
    async cancelConfirmation() {
      await this.$store.dispatch("changeState", {
        orderId: this.order.id,
        state: "cancel_confirmed",
      });
    },
    async changeDel() {
      this.$root.$emit("bp-dropdown:hide");
      if (this.order.delivery_type === "delivery") {
        let payload = {
          field: "delivery_type",
          value: "takeaway",
          orderId: this.order.id,
          address: {},
        };
        this.$store.dispatch("changeDeliveryType", payload);
      } else if (this.order.delivery_type === "takeaway") {
        await this.$store.dispatch("setOrderById", this.order.id);
        this.changeIsModalOpen({ value: true, id: this.order.id });
      }
    },
    async triggerPaymentType() {
      let ptype;
      if (this.order.payment_info.payment_type === Order.PAYMENT_TYPE.pos) {
        ptype = Order.PAYMENT_TYPE.cash;
      } else {
        ptype = Order.PAYMENT_TYPE.pos;
      }
      let payload = {
        field: "payment_info.payment_type",
        value: ptype,
        orderId: this.order.id,
      };
      if (this.order.is_receipt_printed) {
        await printReceipt({
          order: this.order,
          router: this.$router,
          store: this.$store,
          isChangeType: true,
          notCopies: true,
        });
      } else {
        await this.$store.dispatch("changePaymentType", payload);
      }
      this.$root.$emit("bp-dropdown:hide");
      this.readonlyType = true;
    },
    triggerBlackList() {
      this.$root.$emit("bp-dropdown:hide");
      if (this.order.user.blacklist_comment) {
        let payload = {
          field: "blacklist_comment",
          value: "",
          user: this.order.user,
          order: this.order,
        };
        this.$store.dispatch("setOrderUserField", payload);
      } else {
        this.$modal.show("blacklist", {
          user: this.order.user,
          order: this.order,
        });
      }
    },
    async markConfirmed(noEditing) {
      window.console.log("markConfirmed");
      if (this.order.status.is_confirmed) {
        return false;
      }
      const success = await this.$store.dispatch("confirmListOrder", {
        orderId: this.order.id,
        noEditing,
      });
      if (success && this.hasModule(this.modules.receipts)) {
        printReceipt({
          order: this.order,
          router: this.$router,
          store: this.$store,
        });
      }
    },
    editPrivateComment() {
      this.$root.$emit("bp-dropdown:hide");
      this.$modal.show("private-comment", {
        user: this.order.user,
        order: this.order,
      });
    },
    async onDeliveryIconClick() {
      if (
        this.order.delivery_type === Order.DELIVERY_TYPE.delivery &&
        this.order.courier &&
        this.order.status.status === Order.STATUS.open
      ) {
        this.$modal.show("orderLocation", { orderId: this.order.id });
      } else if (
        this.order.delivery_type === Order.DELIVERY_TYPE.delivery &&
        this.order.status.status === Order.STATUS.open &&
        !this.hasModule(this.modules.delivery)
      ) {
        if (
          !this.order.is_receipt_printed &&
          this.$store.getters.department.check_printing_of_receipts &&
          this.order.source !== "aggregator"
        ) {
          alert("Распечатайте чек");
          return;
        }
        this.isLoading = true;
        this.$store.dispatch("accomplishOrder", {
          order: this.order,
        });
        this.isLoading = false;
      } else if (this.order.delivery_type === Order.DELIVERY_TYPE.takeaway) {
        if (
          !this.order.is_receipt_printed &&
          this.$store.getters.department.check_printing_of_receipts &&
          this.order.source !== "aggregator"
        ) {
          alert("Распечатайте чек");
          return;
        }
        this.isLoading = true;
        await this.$store.dispatch("accomplishOrder", {
          order: this.order,
        });
        this.isLoading = false;
      } else if (this.order.delivery_type === Order.DELIVERY_TYPE.stay) {
        if (
          !this.order.is_receipt_printed &&
          this.$store.getters.department.check_printing_of_receipts &&
          this.order.source !== "aggregator"
        ) {
          alert("Распечатайте чек");
          return;
        }
        this.isLoading = true;
        this.$store.dispatch("accomplishOrder", {
          order: this.order,
        });
        this.isLoading = false;
      }
    },
    onReceiptPrint(isNotFiscal) {
      if (!isNotFiscal) {
        this.isReceiptLoading = true;
        setTimeout(() => {
          this.isReceiptLoading = false;
        }, 5000);
      } else {
        this.isNoFiscalReceiptLoading = true;
        setTimeout(() => {
          this.isNoFiscalReceiptLoading = false;
        }, 3000);
      }
      printReceipt({
        order: this.order,
        router: this.$router,
        store: this.$store,
        notCopies: true,
        isNotFiscal: isNotFiscal,
      });
    },
  },
  computed: {
    ...mapGetters(["hasModule", "getReadonly"]),
    isVisible() {
      let result = true;
      if (
        this.order.payment_info.payment_type === "online" &&
        this.order.payment_info.total_paid === 0
      ) {
        result = false;
      }
      return result;
    },
    formattedPhone() {
      let resp = this.order.contacts.phone;
      if (
        this.order.contacts.phone &&
        this.order.contacts.phone.substr(0, 2) == "+7"
      ) {
        let cleaned = ("" + this.order.contacts.phone).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
          resp =
            "+7(" + match[2] + ")" + match[3] + "-" + match[4] + "-" + match[5];
        }
      }

      return resp;
    },
    getRest() {
      return this.order.payment_info.discount_percent_amount[0] === "-"
        ? "+" + this.order.payment_info.discount_percent_amount.slice(1)
        : this.order.payment_info.discount_percent_amount;
    },
    getService() {
      return this.order.payment_info.service_percent_amount[0] === "-"
        ? "+" + this.order.payment_info.service_percent_amount.slice(1)
        : this.order.payment_info.service_percent_amount;
    },
    restAmount() {
      let amount = 0;
      if (this.order.payment_info.rest_from > 0) {
        amount =
          parseFloat(this.order.payment_info.rest_from) -
          parseFloat(this.order.payment_info.total_price);
      }
      return amount;
    },
    isDone() {
      return this.order.status.status === Order.STATUS.done;
    },
    isCanceled() {
      return this.order.status.status === Order.STATUS.canceled;
    },
    isBlacklisted() {
      return this.order.user && this.order.user.blacklist_comment;
    },
    paymentIcon() {
      let icon = "";
      if (this.order.payment_info.payment_type === Order.PAYMENT_TYPE.cash) {
        icon = "payment-cash--done.svg";
      } else if (
        this.order.payment_info.payment_type === Order.PAYMENT_TYPE.pos
      ) {
        icon = "payment-pos--done.svg";
      } else if (
        this.order.payment_info.payment_type === Order.PAYMENT_TYPE.online
      ) {
        if (this.order.payment_info.total_paid !== "0.00") {
          icon = "payment-online--done.svg";
        } else {
          icon = "payment-online--waiting.svg";
        }
      }

      this.changeReadonly();
      return require(`@/assets/img/icons/${icon}`);
    },
    deliveryStageIconData() {
      let icon = "";
      let caption = "";
      if (this.order.delivery_type === Order.DELIVERY_TYPE.delivery) {
        if (this.order.courier) {
          caption = this.order.courier.first_name;
        }
        if (this.order.status.status === Order.STATUS.done) {
          if (
            this.order.is_courier_late ||
            this.order.is_kitchen_n_courier_late
          ) {
            icon = "stage-delivering--flames.svg";
          } else {
            icon = "stage-delivering--done.svg";
          }
        } else if (this.order.courier) {
          icon = "stage-delivering--in-progress.svg";
        } else {
          icon = "stage-delivering.svg";
        }
      } else if (this.order.delivery_type === Order.DELIVERY_TYPE.takeaway) {
        if (this.order.status.status === Order.STATUS.done) {
          icon = "stage-taking-away--done.svg";
        } else {
          icon = "stage-taking-away.svg";
        }
      }
      if (this.order.delivery_type === Order.DELIVERY_TYPE.restaurant) {
        if (this.order.status.status === Order.STATUS.done) {
          icon = "stage-taking-away--done.svg";
        } else {
          icon = "stage-taking-away.svg";
        }
      }
      if (this.order.delivery_type === Order.DELIVERY_TYPE.stay) {
        if (this.order.status.status === Order.STATUS.done) {
          icon = "stage-stay--done.svg";
        } else {
          icon = "stage-stay.svg";
        }
      }
      return {
        icon: require(`@/assets/img/icons/${icon}`),
        caption: caption,
      };
    },
    deliveryStatusClass() {
      let statusClass = "";
      if (
        this.order.delivery_type === Order.DELIVERY_TYPE.delivery &&
        !this.order.courier
      ) {
        statusClass = "";
      } else if (
        this.order.delivery_type === Order.DELIVERY_TYPE.delivery &&
        this.order.courier
      ) {
        return "_in-progress";
      } else if (
        this.order.delivery_type === Order.DELIVERY_TYPE.takeaway &&
        this.order.status.status === Order.STATUS.done
      ) {
        return "_done";
      } else if (
        this.order.delivery_type === Order.DELIVERY_TYPE.stay &&
        this.order.status.status === Order.STATUS.done
      ) {
        statusClass = "";
      }
      return statusClass;
    },
  },
  filters: {
    time(value) {
      if (isToday(new Date(value))) {
        return format(new Date(value), "HH:mm");
      } else {
        return format(new Date(value), "HH:mm (dd.MM)");
      }
    },
  },
};
</script>
